import React from "react"
import PropTypes from "prop-types"

const NotFound = ({ pageContext: { locale, breadcrumbs }, location }) => {
  return (
    <>
      <div className="container margin_120">
        <div className="row"></div>
        <h1>Page Not Found</h1>
        <p>Oops, we couldn't find this page!</p>
      </div>
    </>
  )
}
export default NotFound

NotFound.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
